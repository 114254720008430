import './ModalFile.scss';
import React from "react";
import Modal from "react-responsive-modal";


type Props = {
    openFlag: boolean,
    msg?: string
    closeHandler(): any;
}

const ModalFile: React.FC<Props> = ({children, openFlag, closeHandler}) => {
    return (
        <Modal open={openFlag}
               animationDuration={500}
               onClose={closeHandler}
               center
               focusTrapped
               closeIconSize={35}
               classNames={{
                   closeButton: 'modal-close-btn',
                   closeIcon: 'modal-close-icon',
                   modal: "modal-wrapper",
                   transitionEnter: "modal-enter-opacity-anim",
                   transitionEnterActive: "modal-enter-active",
                   transitionExit: "modal-leave-opacity-anim",
                   transitionExitActive: "modal-leave-active",
               }}>{children}</Modal>
    )
};
export default ModalFile;


