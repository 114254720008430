const ILLEGAL_ACTIONS = [
    {
        title: 'Корупція',
        body: 'Одержання/надання грошей або матеріально цінних речей як підкуп чи плату за які-небудь злочинні, незаконні дії для певної вигоди. Зловживання службовим становищем для особистого збагачення.'
    },
    {
        title: 'Розтрата',
        body: 'Умисне протиправне вилучення майна компанії з метою розпорядження ним як своїм власним. Недоцільне використання будь-яких ресурсів компанії чи байдуже ставлення до майна.'
    },
    {
        title: 'Обман',
        body: 'Навмисне неправильне подання інформації в звітах чи рішеннях задля власних цілей і користі.'
    },
    {
        title: 'Недбалість',
        body: 'Невиконання або неналежне виконання службових обов\'язків через несумлінне ставлення до них, що може нашкодити компанії.'
    }
];

const WORK_CONDITIONS = [
    {
        title: 'Оплата праці',
        body: 'Питання при виплаті нарахувань: заробітної плати чи авансу; щорічної, декретної відпусток та відпустки за власний рахунок; розрахункових коштів при звільненні; компенсації за невикористані дні відпустки.'
    },
    {
        title: 'Охорона праці',
        body: 'Невідповідність умов робочих місць працівників визначеним нормам та порушення безпеки праці в офісах і магазинах компанії. Відсутність проведення інструктажів щодо охорони праці.'
    },
    {
        title: 'Звільнення',
        body: 'Порушення процедури звільнення або ж його неправомірність. Затримка оформлення та видачі документів на звільнення і трудової книжки працівнику, а також розрахункових коштів.'
    },
];

const CONFLICT_ACCIDENTS = [
    {
        title: 'Конфлікти інтересів',
        body: 'Суперечність між особистими інтересами і посадовими обов\'язками, що може вплинути на добросовісне виконання повноважень, об\'єктивність та неупередженість прийняття рішень.'
    },
    {
        title: 'Дискримінація',
        body: 'Будь-які порушення прав людини, менш прихильне ставлення до людини, ніж до інших в аналогічній ситуації через певні ознаки (мова, релігія, вік, стать, раса, майновий стан, наявність інвалідності, місце проживання).'
    },

    {
        title: 'Непорозуміння',
        body: 'Проблемні ситуації між працівниками на робочому місці: суперечки, конфлікти, образи.'
    },
];

export const REFERENCES = [
    {header: 'НЕПРАВОМІРНІ ДІЇ', info: ILLEGAL_ACTIONS, anchor:'illegal'},
    {header: 'УМОВИ ПРАЦІ', info: WORK_CONDITIONS, anchor:'conditions'},
    {header: 'КОНФЛІКТНІ СИТУАЦІЇ', info: CONFLICT_ACCIDENTS, anchor:'conflicts'}
];
