import * as React from "react";
import './MainBanner.scss';

import imgLogo from '../../images/Dovira_Colored.png';

const MainBanner: React.FC = () => {

    return (
        <div className="main-banner">
            <div className="title">Лінія довіри</div>
            <p className="subtitle">В основі взаємовідносин між працівником і роботодавцем завжди лежить довіра. Вона
                допомагає кожному жити і працювати чесно.</p>
            <div className="colored-logo">
                <img src={imgLogo} alt=""/>
            </div>
            <div className="explanation-wrapper">
                <div className="explanation">ФОРА прагне стати кращою мережею магазинів біля дому, де хочеться
                    працювати. Тому для Вас діє Лінія довіри – безпечний діалог між працівниками компанії та
                    керівництвом, допомога у вирішенні організаційних питань, конфліктів та суперечок.
                </div>
            </div>
        </div>
    );
};

export default MainBanner;