import * as React from "react";
import './Anchors.scss';

const Anchors: React.FC = () => {

    return (
        <div className="anchors-wrapper">
            <div className="info">
                <p>За Вашим бажанням звернення може бути конфіденційним.</p>
            </div>
            <div className="anchors-list">
                <a href="#illegal">
                    <div className="illegal-actions"><p>Неправомірні дії</p></div>
                </a>
                <a href="#conditions">
                    <div className="working-conditions"><p>Умови праці</p></div>
                </a>
                <a href="#conflicts">
                    <div className="conflict-situations"><p>Конфліктні ситуації</p></div>
                </a>
            </div>
        </div>
    );
};

export default Anchors;