import React, {FunctionComponent, SyntheticEvent, useEffect, useState} from 'react';
import './FeedBackForm.scss';
import checkedImg from '../../images/checked.png';
import Select, {components} from "react-select";
import Creatable from 'react-select/creatable';
import Recaptcha from "react-recaptcha";
import './CustomSelect.scss'
import classNames from 'classnames';
import ModalFile from "../Footer/ModalFile/ModalFile";
import {DICTIONARY} from "../../enums/dictionary";


const axios = require('axios');

type SelectedState = {
    data: string | null
    isValid: boolean
}

type InputType = {
    data: string
    isValid: boolean
}


const FeedBackForm: FunctionComponent = () => {
    const [valueSelectList, setValueSelectList] = useState<InputType>({data: '', isValid: true});
    const [valueTextInform, setValueTextInform] = useState<InputType>({data: '', isValid: true});
    const [valuePIB, setValuePIB] = useState<InputType>({data: '', isValid: true});
    const [valueContacts, setValueContacts] = useState<InputType>({data: '', isValid: true});
    const [valueUnit, setValueUnit] = useState<InputType>({data: '', isValid: true});
    const [valueSelectPosition, setValueSelectPosition] = useState<InputType>({data: '', isValid: true});
    const [valueTimePeriod, setValueTimePeriod] = useState<InputType>({data: '', isValid: true});
    const [valueQuestionOne, setValueQuestionOne] = useState<InputType>({data: '', isValid: true});

    const [valueQuestionTwo, setValueQuestionTwo] = useState<SelectedState>({data: null, isValid: true});
    const [valueQuestionThree, setValueQuestionThree] = useState<SelectedState>({data: null, isValid: true});

    const [isVerifiedRecaptcha, setVerifiedRecaptcha] = useState<boolean>(false);
    const [isShownRecaptcha, setShowRecaptcha] = useState<boolean>(false);
    const [isVerifiedForm, setVerifiedForm] = useState<boolean>(false);


    const [isButtonActive, setButtonStatus] = useState<boolean | null>(null);
    const [isButtonUsed, setButtonUsed] = useState<boolean>(false);
    const [isShowModalError, setShowModalError] = useState<boolean>(false);
    const [isShowModalConfirm, setShowModalConfirm] = useState<boolean>(false);


    const [isCaptchaKey, setCaptchaKey] = useState<string>("");
    const [googleResposKey, setgoogleResposKey] = useState<string>("");
    const [registrationNumber, setRegistrationNumber] = useState<string>("");
    const [captchaSize, setCaptchaSize] = useState<"normal" | "compact">();

    /***
     * Is show modal window on error filling form
     */
    const toggleStateErrorModal = (val: boolean): void => {
        setShowModalError(val);
    };

    /***
     * Is show modal window on send form
     */
    const toggleStateSendModal = (val: boolean): void => {
        setShowModalConfirm(val);
    };

    /***
     * Check process env for captcha dependencies
     */
    useEffect(() => {
        if (process && process.env && process.env.REACT_APP_ENV === "test") {
            console.log('!!!!test!!!');
            setCaptchaKey('6Lc2Y7QUAAAAACb8mqQwfhC7DQvZH5VbLP_df81D');
            setShowRecaptcha(true);
        } else {
            console.log('!!!!dev or prod!!!');
            process.env.REACT_APP_CAPTURE_PUBLIC_KEY && setCaptchaKey(process.env.REACT_APP_CAPTURE_PUBLIC_KEY);
            setShowRecaptcha(true);
        }
    }, []);

    /***
     * Reset state form to default state.
     */
    const resetStateForm = () => {
        setValueSelectList({data: '', isValid: true});
        setValueTextInform({data: '', isValid: true});
        setValuePIB({data: '', isValid: true});
        setValueContacts({data: '', isValid: true});
        setValueUnit({data: '', isValid: true});
        setValueSelectPosition({data: '', isValid: true});
        setValueTimePeriod({data: '', isValid: true});
        setValueQuestionOne({data: '', isValid: true});
        setValueQuestionTwo({data: null, isValid: true});
        setValueQuestionThree({data: null, isValid: true});
        setVerifiedRecaptcha(false);
        setVerifiedForm(false);
        setButtonStatus(null);
        setShowModalError(false);
    };

    /***
     * Check, are all form fields valid.
     */
    const checkStateForm = () => {
        if (valueSelectList.isValid
            && valueSelectList.data !== ''
            && valueTextInform.isValid
            && valueTextInform.data !== ''
            && valueContacts.isValid
            && valueContacts.data !== ''
            && valueUnit.isValid
            && valueUnit.data !== ''
            && valueSelectPosition.isValid
            && valueSelectPosition.data !== ''
            && valueTimePeriod.isValid
            && valueTimePeriod.data !== ''
            && valueQuestionOne.isValid
            && valueQuestionOne.data !== ''
            && valueQuestionTwo.isValid
            && valueQuestionTwo.data !== null
            && valueQuestionThree.isValid
            && valueQuestionThree.data !== null
            && isVerifiedRecaptcha) {
            setVerifiedForm(true);
        } else {
            setVerifiedForm(false);
        }
    };
    useEffect(() => {
        if (isButtonUsed) {
            checkStateForm();
        }
    }, [valueSelectList, valueTextInform, valuePIB, valueContacts, valueUnit, valueSelectPosition, valueQuestionOne, valueQuestionTwo, valueQuestionThree, isButtonUsed]);

    /***
     * Activate or deactivate button submit form.
     */
    useEffect(() => {
        const checkActivateButton = () => {
            setButtonStatus(false);

            if (isButtonActive === null && !isVerifiedRecaptcha && !isVerifiedForm) {
                setButtonStatus(null);
            }
            if (isButtonActive === null && isVerifiedRecaptcha) {
                setButtonStatus(true);
            }
            if (isVerifiedForm && isVerifiedRecaptcha) {
                setButtonStatus(true);
            }
        };
        checkStateForm();
        checkActivateButton();
    }, [isVerifiedForm, isVerifiedRecaptcha]);

    /***
     * Check, which fields form are invalid.
     */
    const handleErrors = (value: any, comparator: any, cb: any) => {
        if (value.data !== comparator) {
            cb({data: value.data, isValid: true});
        } else {
            cb({data: "", isValid: false});
            setVerifiedForm(false);
        }
    };

    const showErrorFields = () => {
        handleErrors(valueSelectList, '', setValueSelectList);
        handleErrors(valueTextInform, '', setValueTextInform);
        handleErrors(valueContacts, '', setValueContacts);
        handleErrors(valueUnit, '', setValueUnit);
        handleErrors(valueSelectPosition, '', setValueSelectPosition);
        handleErrors(valueTimePeriod, '', setValueTimePeriod);
        handleErrors(valueQuestionOne, '', setValueQuestionOne);
        handleErrors(valueQuestionTwo, null, setValueQuestionTwo);
        handleErrors(valueQuestionThree, null, setValueQuestionThree);
    };

    /***
     *Reset captcha on send form event
     */
    let captchaInstance: any;
    const resetRecaptcha = () => {
        captchaInstance.reset();
    };

    /***
     * Send form data
     */
    const onSendForm = () => {
        setButtonUsed(true);

        if (isButtonActive) {
            console.log('isVerifiedForm', isVerifiedForm);
            if (isVerifiedForm) {

                console.log('reason:', valueSelectList);
                console.log('message:', valueTextInform);
                console.log('name:', valuePIB);
                console.log('contact:', valueContacts);
                console.log('unit:', valueUnit);
                console.log('position:', valueSelectPosition);
                console.log('timePeriod:', valueTimePeriod);
                console.log('questionOne:', valueQuestionOne);
                console.log('is_anonymous:', valueQuestionTwo);
                console.log('is_fora_worker:', valueQuestionThree);
                console.log('recaptcha:', googleResposKey);

                const data = new FormData();

                if (valueSelectList.isValid
                    && valueSelectList.data !== ''
                    && valueTextInform.isValid
                    && valueTextInform.data !== ''
                    && valueContacts.isValid
                    && valueContacts.data !== ''
                    && valueUnit.isValid
                    && valueUnit.data !== ''
                    && valueSelectPosition.isValid
                    && valueSelectPosition.data !== ''
                    && valueTimePeriod.isValid
                    && valueTimePeriod.data !== ''
                    && valueQuestionOne.isValid
                    && valueQuestionOne.data !== ''
                    && valueQuestionTwo.isValid
                    && valueQuestionTwo.data !== null
                    && valueQuestionThree.isValid
                    && valueQuestionThree.data !== null
                    && isVerifiedRecaptcha) {
                    data.append('reason', valueSelectList.data);
                    data.append('message', valueTextInform.data);
                    data.append('name', valuePIB.data);
                    data.append('contact', valueContacts.data);
                    data.append('subdivision', valueUnit.data);
                    data.append('position', valueSelectPosition.data);
                    data.append('time_period', valueTimePeriod.data);
                    data.append('ask_for_help_before', valueQuestionOne.data);
                    data.append('is_fora_worker', valueQuestionTwo.data);
                    data.append('is_anonymous', valueQuestionThree.data);
                    data.append('recaptcha', googleResposKey);
                    axios({
                        method: 'post',
                        url: process.env.REACT_APP_ENDPOINT,
                        headers: {'Content-Type': 'multipart/form-data'},
                        data
                    }).then((response: any) => {
                        console.log('res', response);
                        setShowModalConfirm(true);
                        if (response.data.status) {
                            setRegistrationNumber(response.data.feedback_id);
                            setShowModalConfirm(true);
                        }
                    });
                    resetStateForm();
                    resetRecaptcha();
                } else {
                    setButtonStatus(false);
                    setShowModalError(true);
                    showErrorFields();
                }

            } else {
                setButtonStatus(false);
                setShowModalError(true);
                showErrorFields();
            }
        }
    };

    /***
     * Check, is recaptcha loaded
     */
    const onloadCallback = () => {
        console.log("recaptcha loaded");
    };

    /***
     * Callback for confirm verify recaptcha
     */
    const verifyCallbackRecaptcha = (res: any) => {
        if (res) {
            setVerifiedRecaptcha(true);
            setgoogleResposKey(res);
        }
    };

    /***
     * Event handlers CheckBoxes
     */
    const handleCheckbox = (evt: any, cb: any, comparator: any) => {
        if (evt.target.name === comparator) {
            cb({
                data: evt.target.value,
                isValid: true
            });
        }
    };
    const onChangeEvent = (evt: any): void => {
        handleCheckbox(evt, setValueQuestionTwo, DICTIONARY.FIRST_QUESTION_NAME);
        handleCheckbox(evt, setValueQuestionThree, DICTIONARY.SECOND_QUESTION_NAME);
    };

    /***
     * Event handlers input form fields
     */
    const onClearField = (e: any): void => {
        switch (e.target.id) {
            case DICTIONARY.ID_TEXT_INFORM:
                if (e.target.value === DICTIONARY.ENTER_DATA) {
                    setValueTextInform({data: '', isValid: true});
                }
                break;
            case DICTIONARY.ID_PIB:
                if (e.target.value === DICTIONARY.YOUR_DATA) {
                    setValuePIB({data: '', isValid: true});
                }
                break;
            case DICTIONARY.ID_CONTACTS:
                if (e.target.value === DICTIONARY.CONTACT_DATA) {
                    setValueContacts({data: '', isValid: true});
                }
                break;
            case DICTIONARY.ID_UNIT:
                if (e.target.value === DICTIONARY.YOUR_UNIT) {
                    setValueUnit({data: '', isValid: true});
                }
                break;
            case DICTIONARY.ID_TIME_PERIOD:
                if (e.target.value === DICTIONARY.ENTER_TIME_PERIOD) {
                    setValueTimePeriod({data: '', isValid: true});
                }
                break;
            case DICTIONARY.ID_QUESTION_ONE:
                if (e.target.value === DICTIONARY.ENTER_QUESTION_ONE) {
                    setValueQuestionOne({data: '', isValid: true});
                }
                break;
            default:
                break;
        }
    };

    const handleTextInput = (arg: any, cb: any) => {
        let currentStr = arg.target.value.replace(/\s/g, '');
        if (currentStr.length > 0) {
            cb({data: arg.target.value, isValid: true});
        } else {
            cb({data: arg.target.value, isValid: false});
        }
    };

    const handleTextInform = (e: any): void => {
        handleTextInput(e, setValueTextInform);
    };

    const handlePibInform = (e: any): void => {
        handleTextInput(e, setValuePIB);
    };

    const handleContacts = (e: any): void => {
        handleTextInput(e, setValueContacts);
    };

    const handleUnit = (e: any): void => {
        handleTextInput(e, setValueUnit);
    };

    const handleTimePeriod = (e: any): void => {
        handleTextInput(e, setValueTimePeriod);
    };

    const handleQuestionOne = (e: any): void => {
        handleTextInput(e, setValueQuestionOne);
    };

    /***
     * Event handlers for select fields
     */
    const informAboutOptions = [
        {value: DICTIONARY.MISCONDUCT, label: DICTIONARY.MISCONDUCT},
        {value: DICTIONARY.WORKING_CONDITION, label: DICTIONARY.WORKING_CONDITION},
        {value: DICTIONARY.CONFLICT_SITUATION, label: DICTIONARY.CONFLICT_SITUATION},
        {value: DICTIONARY.OTHER, label: DICTIONARY.OTHER},
    ];

    const onChangeSelect = (changes: { value: string, label: string } | null): void => {
        if (changes && changes.value && changes.value !== "") {
            setValueSelectList({data: changes.value, isValid: true});
        }
    };

    const positionOptions = [
        {value: DICTIONARY.ADMINISTRATOR, label: DICTIONARY.ADMINISTRATOR},
        {value: DICTIONARY.LOADER, label: DICTIONARY.LOADER},
        {value: DICTIONARY.STREET_CLEANER, label: DICTIONARY.STREET_CLEANER},
        {value: DICTIONARY.DEPUTY_STORE_MANAGER, label: DICTIONARY.DEPUTY_STORE_MANAGER},
        {value: DICTIONARY.CASHIER, label: DICTIONARY.CASHIER},
        {value: DICTIONARY.STORE_MANAGER, label: DICTIONARY.STORE_MANAGER},
        {value: DICTIONARY.COOK, label: DICTIONARY.COOK},
        {value: DICTIONARY.HEAD_OF_DEPARTMENT, label: DICTIONARY.HEAD_OF_DEPARTMENT},
        {value: DICTIONARY.MEAT_CRUMB, label: DICTIONARY.MEAT_CRUMB},
        {value: DICTIONARY.FISH_HANDLER, label: DICTIONARY.FISH_HANDLER},
        {value: DICTIONARY.SECURITY, label: DICTIONARY.SECURITY},
        {value: DICTIONARY.PACKER, label: DICTIONARY.PACKER},
        {value: DICTIONARY.BAKER, label: DICTIONARY.BAKER},
        {value: DICTIONARY.MAID, label: DICTIONARY.MAID},
        {value: DICTIONARY.RECEIVER_OF_GOODS, label: DICTIONARY.RECEIVER_OF_GOODS},
        {value: DICTIONARY.SELLER, label: DICTIONARY.SELLER},
        {value: DICTIONARY.CONSULTANT, label: DICTIONARY.CONSULTANT},
        {value: DICTIONARY.MANAGER, label: DICTIONARY.MANAGER},
        {value: DICTIONARY.SOMMELIER, label: DICTIONARY.SOMMELIER},
        {value: DICTIONARY.STACKER_PACKER, label: DICTIONARY.STACKER_PACKER},
        {value: DICTIONARY.SPECIALIST, label: DICTIONARY.SPECIALIST},
    ];

    const MaxLengthInput = (props: any) => <components.Input {...props} maxLength={150}/>;

    const onChangeSelectPosition = (changes: { value: string, label: string } | null): void => {
        if (changes && changes.value && changes.value !== "") {
            setValueSelectPosition({data: changes.value, isValid: true});
        }
    };

    const onSubmitSelect = (event: SyntheticEvent): void => event.preventDefault();

    /***
     * Set captcha size depend on window width.
     */
    useEffect(() => {
        window.innerWidth < 415 ? setCaptchaSize("compact") : setCaptchaSize("normal");
    }, []);


    return (
        <div className="feed-back">
            <div className="feed-back-form-wrapper">
                <div className="feed-back-form">

                    <div className="inform-about">
                        <div className="label-wrap">
                            <label className="label-inform required-field">{DICTIONARY.INFORM_ABOUT}</label>
                        </div>
                        <div className="select-wrap">
                            <form className={classNames("my-select",
                                {"error": !valueSelectList.isValid})} onSubmit={onSubmitSelect}>
                                <Select isSearchable={false}
                                        options={informAboutOptions}
                                        placeholder={DICTIONARY.SELECT_FROM_LIST}
                                        className="selectField"
                                        classNamePrefix="selectField"
                                        noOptionsMessage={obj => obj.inputValue = DICTIONARY.NO_OPTIONS}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                padding: 10,
                                                color: "#464646",
                                                background: state.isSelected ? "#f1f8fb" : state.isFocused ? "#f5f5f5" : "",
                                                outline: state.isFocused ? "1px solid #e0e0e0" : "none",
                                            })
                                        }}
                                        value={(!!valueSelectList && valueSelectList.data !== "") ? {
                                            value: valueSelectList.data, label: valueSelectList.data
                                        } : null}
                                        onChange={onChangeSelect}
                                />
                            </form>
                        </div>
                    </div>

                    <div className="text-inform">
                        <div className="label-wrap-inform">
                            <label className="label-text-inform required-field">{DICTIONARY.TEXT_MESSAGE}</label>
                        </div>
                        <textarea className={classNames(
                            "scrollable-text-box",
                            {"error": !valueTextInform.isValid}
                        )}
                                  maxLength={2000}
                                  onChange={handleTextInform}
                                  onMouseDown={onClearField}
                                  id={DICTIONARY.ID_TEXT_INFORM}
                                  placeholder={DICTIONARY.ENTER_DATA}
                                  value={valueTextInform.data.toString()}
                        />
                    </div>

                    <div className="pib">
                        <div className="pib-wrap">
                            <label className="pib-inform">{DICTIONARY.P_I_B}</label>
                        </div>
                        <input className={classNames("pib-text-box")}
                               maxLength={255}
                               onChange={handlePibInform}
                               onMouseDown={onClearField}
                               id={DICTIONARY.ID_PIB}
                               placeholder={DICTIONARY.YOUR_DATA}
                               value={valuePIB.data.toString()}
                        />
                    </div>

                    <div className="contacts">
                        <div className="label-container-cont">
                            <div className="label-container-cont-top">
                                <label className="label-contacts required-field">{DICTIONARY.CONTACTS}</label>
                                <label className="label-phone">{DICTIONARY.PHONE}</label>
                            </div>
                        </div>
                        <input className={classNames("contacts-text-box",
                            {"error": !valueContacts.isValid})}
                               maxLength={150}
                               onChange={handleContacts}
                               onMouseDown={onClearField}
                               id={DICTIONARY.ID_CONTACTS}
                               placeholder={DICTIONARY.CONTACT_DATA}
                               value={valueContacts.data.toString()}
                        />
                    </div>

                    <div className="unit">
                        <div className="label-container-cont">
                            <div className="label-container-cont-top">
                                <label className="label-contacts required-field">{DICTIONARY.UNIT}</label>
                            </div>
                        </div>
                        <input className={classNames("contacts-text-box",
                            {"error": !valueUnit.isValid})}
                               maxLength={500}
                               onChange={handleUnit}
                               onMouseDown={onClearField}
                               id={DICTIONARY.ID_UNIT}
                               placeholder={DICTIONARY.YOUR_UNIT}
                               value={valueUnit.data.toString()}
                        />
                    </div>

                    <div className="position">
                        <div className="label-wrap">
                            <label className="label-inform required-field">{DICTIONARY.POSITION}</label>
                        </div>
                        <div className="select-wrap">
                            <form className={classNames("my-select",
                                {"error": !valueSelectPosition.isValid})} onSubmit={onSubmitSelect}>
                                <Creatable options={positionOptions}
                                           placeholder={DICTIONARY.YOUR_POSITION}
                                           className="selectField"
                                           classNamePrefix="selectField"
                                           styles={{
                                               option: (provided, state) => ({
                                                   ...provided,
                                                   padding: 10,
                                                   color: "#464646",
                                                   background: state.isSelected ? "#f1f8fb" : state.isFocused ? "#f5f5f5" : "",
                                                   outline: state.isFocused ? "1px solid #e0e0e0" : "none",
                                               })
                                           }}
                                           components={{Input: MaxLengthInput}}
                                           onChange={onChangeSelectPosition}
                                           value={(valueSelectPosition && valueSelectPosition.data !== "") ? {
                                               value: valueSelectPosition.data,
                                               label: valueSelectPosition.data
                                           } : null}
                                           noOptionsMessage={() => null}
                                           formatCreateLabel={(val) => `Додати посаду: ${val}`}
                                />
                            </form>
                        </div>
                    </div>

                    <div className="time-period">
                        <div className="label-container-cont">
                            <div className="label-container-cont-top">
                                <label className="label-contacts required-field">{DICTIONARY.TIME_PERIOD}</label>
                            </div>
                        </div>
                        <input className={classNames("contacts-text-box",
                            {"error": !valueTimePeriod.isValid})}
                               maxLength={255}
                               onChange={handleTimePeriod}
                               onMouseDown={onClearField}
                               id={DICTIONARY.ID_TIME_PERIOD}
                               placeholder={DICTIONARY.ENTER_TIME_PERIOD}
                               value={valueTimePeriod.data.toString()}
                        />
                    </div>

                    <div className="text-inform question-one">
                        <div className="label-wrap-inform">
                            <label className="label-text-inform required-field">{DICTIONARY.QUESTION_ONE}</label>
                        </div>
                        <textarea className={classNames(
                            "scrollable-text-box",
                            {"error": !valueQuestionOne.isValid}
                        )}
                                  maxLength={1500}
                                  onChange={handleQuestionOne}
                                  onMouseDown={onClearField}
                                  id={DICTIONARY.ID_QUESTION_ONE}
                                  placeholder={DICTIONARY.ENTER_QUESTION_ONE}
                                  value={valueQuestionOne.data.toString()}
                        />
                    </div>

                    <div className="questions">
                        <div className="question-two">
                            <div className="left-side-question">
                                <label className="label-question-two required-field">{DICTIONARY.QUESTION_TWO}</label>
                            </div>
                            <div className="right-side-question">
                                <label className="container">{DICTIONARY.YES_LABEL}
                                    <input type="checkbox"
                                           name={DICTIONARY.FIRST_QUESTION_NAME}
                                           value={DICTIONARY.YES}
                                           checked={valueQuestionTwo.data === DICTIONARY.YES}
                                           onChange={onChangeEvent}
                                    />
                                    <span className={classNames("checkmark",
                                        {"error": !valueQuestionTwo.isValid})}
                                    />
                                </label>
                                <label className="container">{DICTIONARY.NO_LABEL}
                                    <input type="checkbox"
                                           name={DICTIONARY.FIRST_QUESTION_NAME}
                                           value={DICTIONARY.NO}
                                           checked={valueQuestionTwo.data === DICTIONARY.NO}
                                           onChange={onChangeEvent}
                                    />
                                    <span className={classNames("checkmark",
                                        {"error": !valueQuestionTwo.isValid})}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="empty-el"/>

                        <div className="question-three">
                            <div className="left-side-question">
                                <label
                                    className="label-question-three required-field">{DICTIONARY.QUESTION_THREE}</label>
                            </div>
                            <div className="right-side-question">
                                <label className="container">{DICTIONARY.YES_LABEL}
                                    <input type="checkbox"
                                           name={DICTIONARY.SECOND_QUESTION_NAME}
                                           value={DICTIONARY.YES}
                                           checked={valueQuestionThree.data === DICTIONARY.YES}
                                           onChange={onChangeEvent}
                                    />
                                    <span className={classNames("checkmark",
                                        {"error": !valueQuestionThree.isValid})}
                                    />
                                </label>
                                <label className="container">{DICTIONARY.NO_LABEL}
                                    <input type="checkbox"
                                           name={DICTIONARY.SECOND_QUESTION_NAME}
                                           value={DICTIONARY.NO}
                                           checked={valueQuestionThree.data === DICTIONARY.NO}
                                           onChange={onChangeEvent}
                                    />
                                    <span className={classNames("checkmark",
                                        {"error": !valueQuestionThree.isValid})}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="empty-el"/>
                    </div>

                    <div className="text-inf">{DICTIONARY.IMPORTANT}</div>

                    <div className={classNames("form-bottom",
                        {"hidden-recaptcha": !isShownRecaptcha})}>
                        {isShownRecaptcha ? <div className="captcha">
                            <Recaptcha
                                size={captchaSize}
                                ref={e => captchaInstance = e}
                                sitekey={isCaptchaKey}
                                render="explicit"
                                verifyCallback={verifyCallbackRecaptcha}
                                onloadCallback={onloadCallback}
                                hl="uk"
                            />
                        </div> : <></>}
                        <button className={classNames(
                            "feed-back-form-submit",
                            {"verified": isButtonActive},
                        )} onClick={onSendForm}>{DICTIONARY.SEND.toLocaleUpperCase()}</button>
                    </div>
                    <ModalFile openFlag={isShowModalError} closeHandler={() => toggleStateErrorModal(false)}>
                        <div className="modal-header">{DICTIONARY.FILLING_ERROR}</div>
                        <div className="modal-body">
                            <p>{DICTIONARY.REQUIRED_FIELD_ERROR}</p>
                            <div className="button-list">
                                <button onClick={() => {
                                    toggleStateErrorModal(false)
                                }}>ОК
                                </button>
                            </div>
                        </div>
                    </ModalFile>
                    <ModalFile openFlag={isShowModalConfirm} closeHandler={() => toggleStateSendModal(false)}>
                        <div className="modal-header modal-header-registered">{DICTIONARY.REGISTERED_APPEAL}</div>
                        <div className="modal-body">
                            <p>{`${DICTIONARY.NUMBER_APPEAL.toUpperCase()} ${registrationNumber}.`}</p>
                            <p className="modal-body-p">{DICTIONARY.THANK}</p>
                            <img src={checkedImg} className="checked-img" alt="checked"/>
                        </div>
                    </ModalFile>

                </div>
            </div>
        </div>

    );
};

export default FeedBackForm;
