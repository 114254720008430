import * as React from "react";
import './styles/App.scss';
import KeyPoints from "./components/KeyPoints/KeyPoints";
import FeedBackForm from "./components/FeedBackForm/FeedBackForm";
import Footer from "./components/Footer/Footer";
import Anchors from "./components/Anchors/Anchors";
import AppealBanner from "./components/AppealBanner/AppealBanner";
import MainBanner from "./components/MainBanner/MainBanner";
import Header from "./components/Header/Header";

if ((process && process.env && process.env.REACT_APP_ENV === "dev") || process.env.REACT_APP_ENV  === "test") {
    console.log('process.env:', process.env)
}

const App: React.FC = () => {
    return (
        <div className="main-container">
            <div className="main">
                <Header/>
                <MainBanner/>
                <AppealBanner/>
                <Anchors/>
                <FeedBackForm/>
                <KeyPoints/>
                <Footer/>
            </div>
        </div>
    );
};

export default App;