export enum DICTIONARY {
    INFORM_ABOUT = 'Повідомити про',
    TEXT_MESSAGE = 'Текст повідомлення',
    P_I_B = 'ПІБ',
    CONTACTS = 'Контакти',
    PHONE = '(телефон, e-mail)',
    UNIT = 'Вкажіть ваш підрозділ?',
    POSITION = 'Посада',
    TIME_PERIOD = 'Часовий період',
    QUESTION_ONE = 'Чи звертались ви за допомогою до керівництва?',
    QUESTION_TWO = 'Чи працюєте Ви на даний момент в компанії ФОРА?',
    QUESTION_THREE = 'Чи бажаєте залишатися анонімом при вирішенні звернення?',
    YES_LABEL = 'так',
    NO_LABEL = 'ні',
    YES = '1',
    NO = '0',
    IMPORTANT = "Важливо! Ваші контакти нам необхідні для зв'язку з Вами, щоб уточнити деталі звернення і повідомити результат.",
    SEND = 'надіслати',
    SELECT_FROM_LIST = 'Оберіть із списку',
    ENTER_DATA = 'Введіть детальний опис вашого звернення',
    YOUR_DATA = "Ваше ім’я та прізвище",
    YOUR_UNIT = "Офіс/магазин/розподільчий центр/інше (№ та адреса)",
    YOUR_POSITION = "Оберіть зі списку/введіть назву та оберіть зі списку / введіть назву",
    ENTER_TIME_PERIOD = 'Коли саме трапилась ситуація, яка стала причиною звернення (день/місяць/час)',
    ENTER_QUESTION_ONE = 'Розкажіть, чому звернення до керівника не допомогло. Якщо Ви не звертались до керівника – вкажіть причину',
    CONTACT_DATA = 'Контактні дані',
    MISCONDUCT = 'Неправомірні дії',
    WORKING_CONDITION = 'Умови праці',
    CONFLICT_SITUATION = 'Конфліктні ситуації',
    FIRST_QUESTION_NAME = '1',
    SECOND_QUESTION_NAME = '2',
    FILLING_ERROR = 'помилка заповнення',
    REQUIRED_FIELD_ERROR = "Заповніть, будь ласка, обов'язкові поля!",
    ID_TEXT_INFORM = 'ID_TEXT_INFORM',
    ID_PIB = 'ID_PIB',
    ID_CONTACTS = 'ID_CONTACTS',
    ID_UNIT = 'ID_UNIT',
    ID_TIME_PERIOD = 'ID_TIME_PERIOD',
    ID_QUESTION_ONE = 'ID_QUESTION_ONE',
    REGISTERED_APPEAL = 'звернення зареєстроване',
    NUMBER_APPEAL = 'Номер вашого звернення - ',
    THANK = 'Дякуємо за довіру!',
    OTHER = 'Інше',
    ADMINISTRATOR = 'Адміністратор',
    LOADER = 'Вантажник',
    STREET_CLEANER = 'Двірник',
    DEPUTY_STORE_MANAGER = 'Заступник керуючого магазином',
    CASHIER = 'Касир',
    STORE_MANAGER = 'Керуючий магазином',
    COOK = 'Кухар',
    HEAD_OF_DEPARTMENT = 'Начальник відділу (офіс)',
    MEAT_CRUMB = 'Обвалювальник м’яса',
    FISH_HANDLER = 'Оброблювач риби',
    SECURITY = 'Охоронник',
    PACKER = 'Пакувальник',
    BAKER = 'Пекар',
    MAID = 'Прибиральниця',
    RECEIVER_OF_GOODS = 'Приймальник товарів',
    SELLER = 'Продавець',
    CONSULTANT = 'Продавець-консультант',
    MANAGER = 'Менеджер (офіс)',
    SOMMELIER = 'Сомельє',
    STACKER_PACKER = 'Укладальник-пакувальник',
    SPECIALIST = 'Фахівець (офіс)',
    NO_OPTIONS = 'Варіант відсутній',
}
