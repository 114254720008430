import * as React from "react";
import './KeyPoints.scss';
import {REFERENCES} from "./constants";

const KeyPoints: React.FC = () => {
    return (
        <div className="key-points-wrapper">
            {REFERENCES.map((item, index) => {
                return (
                    <div className={index % 2 !== 0 ? 'points-list right-align': 'points-list'} key={index} id={item.anchor}>
                        <div className="heading">
                            <h2>{item.header}</h2>
                        </div>
                        <div className="point-item">
                            {item.info.map((itemUnit, index) => {
                                return (
                                    <div key={index}>
                                        <p className="paragraph-title">{itemUnit.title}</p>
                                        <p>{itemUnit.body}</p>
                                    </div>)
                            })}
                        </div>
                        {index !== REFERENCES.length-1 ? <hr className='line'/>: ''}
                    </div>
                )
            })
            }
        </div>
    );
};

export default KeyPoints;