import * as React from "react";
import './AppealBanner.scss';


const AppealBanner: React.FC = () => {

    return (
        <div className="appeal-banner">
            <div className='appeal-info'>
                <p>Стали свідком проблемної ситуації?</p>
                <p>Знаєте про порушення з боку колег?</p>
                <p>Маєте робочі питання чи інформацію, якою хочете поділитися?</p>
                <p>Виникли непорозуміння, які не можете вирішити самостійно?</p>
            </div>
            <div className='appeal-sub'>Звертайтеся за допомогою до Лінії довіри!</div>
        </div>
    );
};

export default AppealBanner;