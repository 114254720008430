import * as React from "react";
import './Footer.scss';
import {useState} from "react";
import ModalFile from "./ModalFile/ModalFile";

const Footer: React.FC = () => {
    const [isOpen, toggleOpen] = useState(false);

    function toggleState(val: boolean) {
        toggleOpen(val);
    }

    function openFile() {
        window.open(`${process.env.REACT_APP_HOST}/uploads/statements.pdf`);
    }

    function saveFile() {
        let req = new XMLHttpRequest();
        req.open("GET", `${process.env.REACT_APP_HOST}/uploads/statements.pdf`, true);
        req.responseType = "blob";
        req.onreadystatechange = function () {
            if (req.readyState === XMLHttpRequest.DONE && req.status === 200) {
                let link = document.createElement('a');
                document.body.appendChild(link);
                link.href = window.URL.createObjectURL(req.response);
                link.download = "Statements.pdf";
                link.click();
            }
        };
        req.send();

    }

    return (
        <footer>
            <div className="subfooter">
                <div className="info">
                    <p>Ми прагнемо допомогти кожному і в своїй роботі керуємося неупередженістю, правилами компанії та, перш за все, людяністю.</p>
                    <p className="statements-text"><p>Цікаво, як працює Лінія?</p><p> Знайомтеся з Положенням про Лінію довіри.</p></p>
                </div>
                <div className="statements" onClick={() => {
                    toggleState(true);
                }}><p>Завантажити Положення про лінію довіри</p></div>
            </div>
            <div className="motto">
                <div>ДАВАЙТЕ СПІЛЬНО БУДУВАТИ ЧЕСНУ, ПРОЗОРУ, ЕТИЧНУ ТА УСПІШНУ КОМПАНІЮ!</div>
                <div className="org">
                    <p><span>© Dovira</span><span>1.0.49</span></p>
                </div>
            </div>
            <ModalFile openFlag={isOpen} closeHandler={() => toggleState(false)}>
                <div className="modal-header">Положення про лінію довіри</div>
                <div className="modal-body">
                    <p>Відкрити цей файл або зберегти його на пристрій?</p>
                    <div className="button-list">
                        <button onClick={() => {
                            openFile()
                        }}>Відкрити
                        </button>
                        <button onClick={() => {
                            saveFile()
                        }}>Зберегти
                        </button>
                        <button onClick={() => {
                            toggleState(false)
                        }}>Скасувати
                        </button>
                    </div>
                </div>
            </ModalFile>
        </footer>
    );
};

export default Footer;
